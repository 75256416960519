<template>

<Header />



<section style="padding-top:50px; padding-bottom: 100px;">
	
	<div class="container">

		<div>
			<h6> Home <span class="color-1"> > </span> Apartments <span class="color-1"> > </span> {{name}} </h6>
		</div>

		<div class="row">

			<div class="col-md-8 mt-3">
				<h4> {{name}} </h4>
				<h6> <i class="bi bi-geo-alt"></i> {{address}}, {{ county }} </h6>
			</div>

			<div class="col-md-4 mt-3" style="text-align: right;">
				<button class="btn btn-1 zoom">Reserve your apartment stay <i class="bi bi-plus"></i> </button>
			</div>
			
		</div>



<div class="row mt-3">

	<div class="col-md-8">
		<div :style=" { 'backgroundImage' : 'url('+this.$store.state.url+'assets/apartment-images/'+image+')', 'height' : '400px', 'backgroundSize' : 'cover', 'backgroundPosition' : 'center' } " class="rounded"></div>


		<div class="mt-3">
			<button class="btn btn-1 zoom"> <i class="bi bi-camera"></i> View gallery</button>
		</div>

	</div>


	<div class="col-md-4">

		<div class="row mt-3">

			<div class="col-md-6 col-6">
				<h6>Ksh. {{price}} </h6>
			</div>

			<div class="col-md-6 col-6">
				<div style="text-align: right;">
	<button class="btn"> <i class="bi bi-eye color-1"></i> House rules </button>
		</div>
			</div>
			
		</div>


		<h3>Amenities</h3>

		<div>
			<p v-if="amenities.length==0" class="text-danger">Not available at the moment</p>
			
			<button style="border:solid 1px #f5f5f5; margin:5px;" class="btn" v-for="a in amenities"> <i class="bi bi-check color-1"></i>  {{ a.name }}  </button>
			
		</div>


		<h3 class="mt-3">Rooms</h3>

		<div class="table-responsive">

			<table class="table">

				<tbody>
		<tr> <th>Bedrooms : </th> <td> {{bedrooms}} </td> </tr>
			<tr> <th>Bathrooms : </th> <td> {{bathrooms}} </td> </tr>
			<tr> <th>Kitchens : </th> <td> {{kitchens}} </td> </tr>
				</tbody>
				
			</table>
			
		</div>

	</div>

	
</div>


		
	</div>

</section>





<Footer />

</template>


<script>
	import Header from './layouts/Header'
	import Footer from './layouts/Footer'
	import 'animate.css';
	import axios from 'axios'

	
	export default{
		name : 'home',
		components : {Header,Footer},
		data(){
			return{

			id : '',
			name : '',
			category : '',
			county : '',
			address : '',
			price : '',
			rules : '',
			kitchens :'',
			bedrooms : '',
			bathrooms : '',
			text : 'Save changes',
			disabled : false,
			image : '',
			gallery : [],
			amenities : []
				
			}
		},
		methods : {

				async  get_amenities(){
				const res = await axios.get(this.$store.state.url+'api/get-amenities/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.amenities = res		
				 
			},

				async  apartment_gallery(){
				const res = await axios.get(this.$store.state.url+'api/apartment-gallery/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.gallery = res		
				 
			},

				async get_details(){
				const res = await axios.get(this.$store.state.url+'api/apartment-details/'+this.id).then(function(response){
					return response.data
				}).catch(function(error){
					console.log(error)
				})
				this.details = res

				res.forEach((data)=>{
					this.name = data.name 
					this.category = data.category
					this.county = data.county
					this.address = data.address
					this.price = data.price
					this.rules = data.description
					this.bathrooms = data.bathrooms
					this.bedrooms = data.bedrooms
					this.kitchens = data.kitchens
					this.image = data.image

				}) 
		
				 
			}
			
		},

		created(){
		this.id = this.$route.params.id 
		this.get_amenities()
		this.get_details()
		this.apartment_gallery()
			
		}
	}

</script>