import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home'
import About from '../components/About'
import Contact from '../components/Contact'
import ApartmentDetails from '../components/ApartmentDetails'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },

    {
    path: '/about',
    name: 'about',
    component: About
  },


     {
    path: '/contact',
    name: 'contact',
    component: Contact
  },


     {
    path: '/apartment-details/:id',
    name: 'apartment-details',
    component: ApartmentDetails
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
