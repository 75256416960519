<template>
	
	<section>
	
<div style="text-align: center;">

	<div>
		<img src="/assets/images/new-logo.png" style="height: 50px;">
	</div>

	<div>
		<router-link to="/" class="btn color-1">Home</router-link>
		<router-link to="/" class="btn color-1">Apartments</router-link>
		<router-link to="/contact" class="btn color-1">Contact</router-link>
	</div>

	<h6>Safiri By Kenyans</h6>
	<h6>Safiri By Kenyans © 2024. All Rights Reserved</h6>

</div>


</section>


</template>