<template>
	

	<div class="container">


<nav class="navbar navbar-expand-lg navbar-ligh bg-ligh rounded mt-3 header-design">
  <div class="container-fluid">
    <router-link to="/" class="navbar-brand">
    	<img src="/assets/images/new-logo.png" class="logo">
    </router-link>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mx-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link to="/" class="nav-link active" aria-current="page">Home</router-link>
        </li>

         <li class="nav-item">
          <router-link to="/" class="nav-link active" aria-current="page">Apartments <b><i class="bi bi-plus color-1"></i></b> </router-link>
        </li>

         <li class="nav-item">
          <router-link to="/about" class="nav-link active" aria-current="page">About</router-link>
        </li>

         <li class="nav-item">
          <router-link to="/contact" class="nav-link active" aria-current="page">Contact</router-link>
        </li>

        
       
      </ul>
      <div class="d-flex">
        <button class="btn btn-outline-success zoom" style="margin-right: 10px;">Login</button>
        <button class="btn btn-1 zoom">Register</button>


      </div>
    </div>
  </div>
</nav>	



</div>


</template>