import { createStore } from 'vuex'

export default createStore({
  state: {
    url : 'https://server.safiribykenyans.co.ke/'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
